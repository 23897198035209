// API DEMO
const API = 'https://api.softmedic.co:8443/SoftmedicAPIDemo';
const API_KEY = '';

export const environment = {
  apiKey: API_KEY,
  apiUrl: `${API}/api`,
  apiFicheros: `${API}`,
  env: 'staging',
  production: false,
  reportesUrlPdf: `${API}/PDFRecurso?`,
};
